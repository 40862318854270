<!--钥匙完善率-->
<template>
  <div class="rate_page">
    <!--rate_page_list start-->
    <div class="rate_page_list">
      <div class="personage_head">
        <div class="personage_head_top">
          <div class="personage_head_txt">类型:</div>
          <ul class="personage_head_ul">
            <li>新房</li>
            <li class="active">住宅</li>
          </ul>
        </div>
        <div class="personage_head_top">
          <div class="personage_head_txt">租售类型:</div>
          <ul class="personage_head_ul">
            <li>出售</li>
            <li class="active"><router-link class="color_blue" to="/personage/SetLease">出租</router-link> </li>
          </ul>
        </div>
      </div>
      <ul class="personage_ul">
        <li v-for="(item, index) in 2" :key="index">
          <div class="personage_ul_img m-r-20">
            <img
                src="https://pic1.ajkimg.com/display/ajk/f4ef3c1ab08cb7a9ed376343005a85ea/640x420c.jpg?t=1"
                alt=""
            />
            <div class="personage_img_marking">A类</div>
          </div>
          <div class="personage_ul_main f-1">
            <div class="personage_ul_name">富田城·九鼎华府富田城·九鼎华府</div>
            <div class="personage_ul_title">
              2室1厅1厨1卫 | 88 | 8次 | 2天 | 王珊珊
            </div>
            <div class="personage_ul_tags">
              <span class="tag">独家房源</span>
              <span class="tag">有车位</span>
              <span class="tag">有视频</span>
            </div>
            <div class="personage_ul_price flex-between">
              <div class="flex">
                <div class="personage_ul_price_left m-r-20">
                  <span>1800元/月</span>
                  <img src="../../assets/image/personage/up2.png" alt="" />
                </div>
                <div class="personage_ul_price_right">
                  <span>12652.2元/m²</span>
                  <img src="../../assets/image/personage/up1.png" alt="" />
                </div>
              </div>

              <div class="personage_ul-bot">
                <button class="rate_page_btn m-r-20">取消锁定</button>
              </div>
            </div>

          </div>
        </li>
      </ul>
      <div class="paging">
        <el-pagination
            background
            layout="prev, pager, next, jumper, ->, total"
            :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!--rate_page_list end-->
  </div>
</template>

<script>

export default {


  data() {
    return {
      page: 1, //页数
      total: 0, //条数
      list: [], //列表
    };
  },
};
</script>

<style lang="less" scoped>
.personage_head {
  padding: 30px 0;
  border-bottom:1px solid #EBEBEB;
  .personage_head_top {
    display: flex;
    &:last-child {
      margin-top: 15px;
    }
    .personage_head_txt {
      margin-right: 30px;
      width: 70px;
      color: #333333;
      font-size: 16px;
      font-weight: bold;
    }
    .personage_head_ul {
      display: flex;
      flex-wrap: wrap;
      li {
        cursor: pointer;
        color: #333333;
        font-size: 16px;
        margin-right: 40px;
        &:last-child {
          margin-right: 0;
        }
      }
      .active {
        color: #3273f6;
        font-weight: bold;
      }
    }
  }
}
.rate_page_head{
  background:#fff;padding:0 40px;align-items:center;margin-bottom:10px;height:64px;
  .rate_nav{
    .li{font-size:18px;color:#666;margin-right:55px;cursor:pointer;position:relative;line-height:64px;}
    .active{color:#3273F6;}
    .active:after{width:24px;height:2px;background:#3273F6;position:absolute;left:50%;bottom:0;margin-left:-12px;display:block;content:"";}
  }
  .rate_total{font-size:18px;}

}
.rate_page_list{background:#fff;padding:0 50px;}
.personage_ul_tags{
  display:flex;margin:0 -5px;
  .tag{background:#F5F5F5;height:28px;border-radius:3px;padding:0 20px;margin:5px;font-size:14px;color:#999;line-height:28px;}
}
.rate_page_btn{width:112px;height:40px;border:1px solid #3273F6;border-radius:4px;color:#3273F6;font-sze:16px;text-align:center;line-height:40px;background:#fff;outline:none;cursor:pointer}
.rate_page_btn.orange{background:#FFA740;border:1px solid #FFA740;color:#fff;}
.rate_page_btn.blue{background:#3273F6;border:1px solid #3273F6;color:#fff;}
.personage_ul {
  li {
    padding:40px 0;
    display: flex;
    border-bottom: 1px solid #ebebeb;
    .personage_ul_img {
      img {
        width: 220px;
        height: 165px;
        border-radius: 8px;
      }
      position: relative;
      .personage_img_marking {
        top: 0;
        left: 0;
        position: absolute;
        width: 56px;
        height: 32px;
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        line-height: 32px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 8px 0px 8px 0px;
      }
    }
    .personage_ul_main {
      height: 165px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .personage_ul_name {
        color: #333333;
        font-size: 18px;
        font-weight: bold;
      }
      .personage_ul_title {
        color: #999999;
        font-size: 14px;
      }
      .personage_ul_price {
        display: flex;
        align-items: flex-end;
        .personage_ul_price_left {
          display: flex;
          align-items: center;
          span {
            color: #ff2121;
            font-size: 22px;
            font-weight: bold;
          }
        }
        .personage_ul_price_right {
          display: flex;
          align-items: center;
          span {
            color: #999999;
            font-size: 18px;
          }
        }
        img {
          width: 11px;
          height: 14px;
          margin-left: 10px;
        }
      }
      .personage_ul_footer {
        width: 100%;
        align-items: flex-end;
        .personage_ul_footer_phone {
          width: 36px;
          height: 36px;
          background: #ffe7e3;
          border-radius: 50%;
        }
        .personage_ul_footer_img {
          img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
          }
          margin-right: 10px;
        }
        .personage_ul_footer_main {
          height: 48px;
          align-items: flex-start;
          flex-direction: column;
        }
        .personage_ul_footer_name {
          color: #333333;
          font-size: 16px;
          font-weight: bold;
        }
        .personage_ul_footer_time {
          span {
            color: #ff9600;
            font-size: 14px;
            &:first-child {
              margin-right: 40px;
            }
          }
        }
      }
    }
  }
}
</style>
